







import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ProductLinkTabs from '@/components/content/ProductLinkTabs.vue'

@Component({
  components: {
    ProductLinkTabs
  }
})
export default class ProductHeader extends Vue {
  get productName () {
    return this.$store.getters.getActiveProduct.name
  }

  @Prop() breadcrumbItemOverride?: any[]
  breadcrumbItems: any[] = [
    {
      text: 'Home',
      to: { name: 'home' }
    },
    {
      html: this.productName,
      to: { name: 'product' }
    }
  ]

  @Watch('productName')
  productChanged (productName: any) {
    this.breadcrumbItems = [
      {
        text: 'Home',
        to: { name: 'home' }
      },
      {
        html: this.productName,
        to: { name: 'product' }
      }
    ]
  }
}
