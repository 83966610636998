




























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ProductLinkTabs extends Vue {
  get productKey () {
    return this.$route.params.productKey
  }

  getTabBgColour (active:boolean) {
    if (active) {
      return this.$store.getters.getBGStyle3
    } else {
      return this.$store.getters.getBGStyle1
    }
  }

  get hasGvds () {
    return this.$store.getters.getProductByKey(this.productKey) ? this.$store.getters.getProductByKey(this.productKey).gvds.length : false
  }

  get hasSlideDecks () {
    return this.$store.getters.getProductByKey(this.productKey) ? this.$store.getters.getProductByKey(this.productKey).slideDecks.length : false
  }

  get hasResources () {
    return this.$store.getters.getResourcesForProductKey(this.productKey).length
  }

  get gvdKey () {
    return this.$store.getters.getActiveGvd.key
  }

  get slideDeckKey () {
    return this.$store.getters.getActiveSlideDeck ? this.$store.getters.getActiveSlideDeck.key : ''
  }

  isActive (routeName: string) {
    return (this.$route.name || '').indexOf(routeName) >= 0
  }
}
