














import { Component, Vue } from 'vue-property-decorator'
import GvdViewer from './@components/GvdViewer.vue'
import ProductHeader from '@/components/content/ProductHeader.vue'
import store from '@/store/store'
import { Product } from '@/store/modules/product'
import { Gvd, GvdUpdate } from '@/store/modules/gvd'
import { SlideDeckUpdate } from '@/store/modules/slideDeck'
import ChangeLogModal from '../ChangeLog.modal.vue'

@Component({
  components: {
    GvdViewer,
    ProductHeader,
    ChangeLogModal
  }
})
export default class GvdDashboard extends Vue {
  gvd: Gvd = this.$store.getters.getActiveGvd
  documentError?: string
  activeChangeLog: GvdUpdate[] | SlideDeckUpdate[] = []
  async mounted () {
    await this.$store.dispatch('loadResourceTypes')
    // load resources so that tab menu can update acordingly
    this.gvd = this.$store.getters.getActiveGvd
  }

  onDocumentErrored (e: any) {
    this.documentError = e.text
  }

  openGvdChangeLogModal (key: string) {
    this.$store.dispatch('loadGvdChangeLog', key).then((updates: GvdUpdate[]) => {
      this.activeChangeLog = updates
      this.$bvModal.show('change-log-modal')
    })
  }

  async beforeRouteUpdate (to: any, from: any, next: Function) {
    if (store.getters.getActiveGvd.key === to.params.gvdKey) {
      return next()
    }
    const _product: Product = await store.dispatch('loadProduct', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    if (!to.params.gvdKey) {
      if (!_product.gvds || !_product.gvds.length) {
        return next(`/product/${_product.key}`)
      }
      return next(`/product/${_product.key}/gvd/${_product.gvds[0].key}`)
    }
    const _gvd: Gvd = await store.dispatch('loadGvd', to.params.gvdKey)
    if (_gvd) {
      await store.dispatch('loadGvdSections', _gvd.gvdSections)
      await store.dispatch('loadGvdPages', _gvd.gvdSections)
      await store.dispatch('loadProductResourcesForMarket', to.params.productKey)
      this.gvd = store.getters.getActiveGvd
      return next()
    }
    return next(`/product/${_product.key}`)
  }

  async beforeRouteEnter (to: any, from: any, next: Function) {
    const _product: Product = await store.dispatch('loadProduct', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    console.log(_product)
    if (!to.params.gvdKey) {
      if (!_product.gvds || !_product.gvds.length) {
        return next(`/product/${_product.key}`)
      }
      return next(`/product/${_product.key}/gvd/${_product.gvds[0].key}`)
    }
    const _gvd: Gvd = await store.dispatch('loadGvd', to.params.gvdKey)
    if (_gvd) {
      await store.dispatch('loadGvdSections', _gvd.gvdSections)
      await store.dispatch('loadGvdPages', _gvd.gvdSections)
      await store.dispatch('loadProductResourcesForMarket', to.params.productKey)
      return next()
    }
    return next(`/product/${_product.key}`)
  }
}
